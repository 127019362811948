import * as React from "react";

import { graphql, Link, useStaticQuery } from "gatsby";
import { Card } from "@components/Card";
import { EmailList as EmailListForm } from "@components/EmailListForm";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { CycleGraphicSVG } from "@components/EP3Document/CycleGraphic";
import toolkitIcon from "@/icons/get-help-page/Toolkit-darkblue.svg";
import { DynamicComponent } from "@components/DynamicComponent";
import * as styles from "./index.module.css";

export const BottomCards = () => {
  const data = useStaticQuery(graphql`
    query {
      content: allSanityCustomTextBlock(
        filter: {target: {regex: "/^ep3-tool-box-/"}}
        sort: {order: ASC, fields: target}
      ) {
        nodes {
          content: _rawContent(resolveReferences: {maxDepth: 10})
          target
        }
      }
    }
  `);
  const nodes = new Map<string, any[]>(data.content.nodes.map(({ content, target }) => ([target, content])));
  return (
    <div className={styles.bottomCards}>
      <Card cardColor="primary">
        <img className="w-24 h-24" src={toolkitIcon} alt={"toolkit"} />
        <DynamicComponent blocks={nodes.get("ep3-tool-box-1")} />
        <div className={styles.callToAction}>
          <Link
            to="/toolkit/"
            className="transition hover:-translate-y-1 hover:drop-shadow-lg bg-secondary px-4 py-2 text-lg text-white focus:text-white visited:text-white"
          >
            Read more
          </Link>
        </div>
      </Card>
      <Card cardColor="primary">
        <FaEnvelopeOpenText className={`text-primary w-24 h-24 mr-2`} />
        <DynamicComponent blocks={nodes.get("ep3-tool-box-2")} />
        <div className={styles.emailForm}>
          <EmailListForm />
        </div>
      </Card>
      <Card cardColor="primary">
        <CycleGraphicSVG className="block max-w-24 max-h-24 h-24 w-24 mb-2" />
        <DynamicComponent blocks={nodes.get("ep3-tool-box-3")} />
        <div className={styles.callToAction}>
          <Link
            to="/get-started/"
            className="transition hover:-translate-y-1 hover:drop-shadow-lg  bg-secondary px-4 py-2 text-lg text-white focus:text-white visited:text-white"
          >
            Read more
          </Link>
        </div>
      </Card>
    </div>
)};
