import * as React from "react";
import { Layout } from "@components/Layout";
import { graphql, Link } from "gatsby";

import daliIcon from "../icons/get-help-page/dali-icon.svg";
import eventsIcon from "../icons/get-help-page/events-icon.svg";
// import registerIcon from "../icons/get-help-page/register-icon.svg";
// import forumsIcon from "../icons/get-help-page/forums-icon.svg";
import toolkitIcon from "../icons/get-help-page/toolkit-white.svg";
import solveAProblemIcon from "../icons/get-help-page/solve-problem-icon.svg";
import HBCU_BSI_icon from "../icons/get-help-page/hbcu-bsi-site-visit.svg";
import { Provider } from "jotai";
import { heroAtom, seoAtom } from "@/store";
import GatsbyImage from "gatsby-image";
import { Container } from "@components/Layout/Container";
import { Header } from "@components/Header";
import { BreadCrumbs } from "@components/Breadcrumbs";
import { Title } from "@components/Header/Title";
import { SubTitle } from "@components/Header/SubTitle";
import { BottomCards } from "../components/BottomCards";
import { Helmet } from "react-helmet";

const items = [
  // {
  //   label: "Discussion Forums",
  //   link: "/forum",
  //   icon: forumsIcon,
  // },
  // {
  //   label: "Register to see examples and comments",
  //   link: "/login",
  //   icon: registerIcon,
  // },
  {
    label: "Use Toolkit for Departments Under Threat",
    link: "/toolkit",
    icon: toolkitIcon,
  },
  {
    label: "See Upcoming Events",
    link: "/events",
    icon: eventsIcon,
  },
  {
    label: "Join a DALI",
    link: "/dali",
    icon: daliIcon,
  },
  {
    label: "Request a HBCU/BSI Site Visit",
    link: "/hbcu-bsi-site-visits",
    icon: HBCU_BSI_icon,
  },
  {
    label: "Map Your Program’s Goals",
    link: "/goal-map",
    icon: solveAProblemIcon,
  },
];

const linkBgTransition = "transition-colors duration-300 hover:bg-primary bg-tertiary";

const linkInsideFlow = `flex flex-col items-center justify-center`;
const linkClassNames = `w-full md:w-full h-32 sm:m-auto h-48 ${linkInsideFlow} ${linkBgTransition}`;

type GetHelpAndEngage = {
  data?: Maybe<{ hero: string | GatsbyImage; sanityPage?: any; footer?: Maybe<SanityGlobalFooter> }>;
};

const Page = ({ data }: GetHelpAndEngage) => (
  <Provider
    initialValues={[
      [heroAtom, data?.sanityPage?.hero ?? data?.hero],
      [seoAtom, { title: "Get Help and Engage", description: "Get help and engage with other EP3 members." }],
    ]}
  >

    <Helmet>
      <title> Get Help and Engage | EP3 Guide</title>
    </Helmet>
    <Layout>
      <Container>
        <Header>
          <BreadCrumbs paths={[{ url: "/", label: "Home" }]} title={"Get Help and Engage"} />
          <Title>Get Help and Engage with the Community</Title>
          <SubTitle>Choose an activity below to get help and engage with the community…</SubTitle>
        </Header>
        <nav className="grid grid-cols-1 lg:grid-cols-3 items-center justify-center gap-8 center m-8">
          {items.map((item) => {
            return (
              <Link key={item.label} to={item.link}>
                <div className={linkClassNames}>
                  <img className="w-16 h-16" src={item.icon} alt={item.label || ""} />
                  <p className="block text-white p-3">{item.label}</p>
                </div>
              </Link>
            );
          })}
        </nav>
        <BottomCards />
      </Container>
    </Layout>
  </Provider>
);

export default Page;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "hero-goal-page.jpg" }) {
      ...heroImageFragment
    }
  }
`;
